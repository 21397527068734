import http from './axios'

// 推荐房源
export const recommendHouse = (data) => http('/e-find/recommendHouse2', data, 'post')

// 地图找房
export const listMapAsset = (data) => http('/e-find/mapFindHouse2', data, 'post')

// 房源详情
export const getHouseDetail = (data) => http('/e-find/getHouseDetail2',data, 'get')

// 房源详情
export const helpFindHouse = (data) => http('/e-find/helpFindHouse',data, 'post')

// 服务点
export const listServicePoint = () => http('/e-find/servicePoint', 'get')

// 咨询列表
export const getNewDetail = (data) => http('/e-find/getNewDetail',data, 'post')

