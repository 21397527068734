// /**
//  * {
//  *   dev: '开发环境配置信息',
//  *   test: '测试环境配置信息',
//  *   prod: '线上环境配置信息'
//  * }
//  */
// function conf (base = {}) {
//   if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'testing') { // 生产环境下
//     const env = process.env.ENV_CONFIG || 'dev'
//     return base[env] || base.dev
//   }
//   // 开发环境
//   return base.dev
// }

// // baseUrl
// export const baseUrl = conf({
//   dev: 'http://127.0.0.1:3000',
//   test: 'http://127.0.0.1:3000',
//   prod: 'http://api.jssyjt.net'
// })

export const baseUrl = 'https://api.jssyjt.net'
// export const baseUrl = 'http://127.0.0.1:10020'
