import axios from 'axios'
import { baseUrl } from '../../env'

// var axiosBaseUrl = ''
export default async (url = '', data = {}, type = 'GET', headers = {}) => {
  type = type.toLowerCase()
  switch (type) {
    case 'get':
      console.log(baseUrl)
      return axios.get(baseUrl + url, data)
        .then((res) => {
          return res
        })
        .catch((error) => {
          return error
        })
    case 'post':
      return axios.post(baseUrl + url, data, headers)
        .then((res) => {
          return res
        })
        .catch((error) => {
          return error
        })
    case 'put':
      return axios.put(baseUrl + url, data)
        .then((res) => {
          return res
        })
        .catch((error) => {
          return error
        })
    case 'delete':
      return axios.delete(baseUrl + url + '/', data)
        .then((res) => {
          return res
        })
        .catch((error) => {
          return error
        })
    default:
      return axios({
        method: type,
        baseUrl: baseUrl,
        url: url,
        data: data,
        timeout: 10000,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          // 'X-Frame-Options': 'DENY', // 告诉浏览器不要（DENY）把这个网页放在iFrame内，通常的目的就是要帮助用户对抗点击劫持。
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      }).then((res) => {
        return res
      }).catch((error) => {
        return error
      })
  }
}
